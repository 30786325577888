var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('van-nav-bar',{attrs:{"title":"点位设计计算工具","right-text":"截屏"},on:{"click-right":_vm.toImage}}),_c('div',{ref:"imageTofile"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"plating"},[_c('span',[_vm._v("距离计算")]),_c('van-icon',{attrs:{"name":"info-o","size":"16px"},on:{"click":function($event){_vm.show1 = true}}})],1),_c('van-field',{attrs:{"type":"number","placeholder":"请输入营业区域天花板高度H1"},model:{value:(_vm.c5),callback:function ($$v) {_vm.c5=$$v},expression:"c5"}}),_c('van-field',{attrs:{"type":"number","placeholder":"门框高度H3/m"},model:{value:(_vm.c6),callback:function ($$v) {_vm.c6=$$v},expression:"c6"}}),_c('van-divider',{style:({
          color: '#1989fa',
          borderColor: '#1989fa',
          padding: '0 16px'
        }),attrs:{"content-position":"left"}},[_vm._v("计算结果")]),_c('van-cell-group',[_c('van-cell',{attrs:{"title":"最近安装距离/m","value":_vm.r1}}),_c('van-cell',{attrs:{"title":"最远安装距离/m","value":_vm.r2}},[_vm._v(" "+_vm._s(_vm.r2)+" "),(_vm.r2Label)?_c('div',[_c('div',[_vm._v(" ")])]):_vm._e()]),(_vm.r2Label)?_c('div',{staticClass:"r2-label"},[_c('van-icon',{attrs:{"name":"warning-o","color":"#ee0a24"}}),_vm._v(" "+_vm._s(_vm.r2Label)+" ")],1):_vm._e()],1)],1),_c('div',{staticClass:"main"},[_vm._m(0),_c('van-field',{attrs:{"type":"number","placeholder":"视频监控摄像机/DS-2XD2246"},model:{value:(_vm.d2),callback:function ($$v) {_vm.d2=$$v},expression:"d2"}}),_c('van-field',{attrs:{"type":"number","placeholder":"AI客流摄像机/AB-DID7147HWD-IZ"},model:{value:(_vm.d3),callback:function ($$v) {_vm.d3=$$v},expression:"d3"}}),_c('van-divider',{style:({
          color: '#1989fa',
          borderColor: '#1989fa',
          padding: '0 16px'
        }),attrs:{"content-position":"left"}},[_vm._v("计算结果")]),_c('van-cell-group',[_c('van-cell',{attrs:{"title":"8路NVR/DS-7608N-E1-V3","value":_vm.d4}}),_c('van-cell',{attrs:{"title":"16路NVR/DS-7616N-E2-V3","value":_vm.d5}}),_c('van-cell',{attrs:{"title":"8口POE交换机/DS-7608N-E1-V3","value":_vm.d6}}),_c('van-cell',{attrs:{"title":"16口POE交换机/TL-SG2218PE","value":_vm.d7}}),_c('van-cell',{attrs:{"title":"4T硬盘/ST4000VX000","value":_vm.d8}}),_c('van-cell',{attrs:{"title":"6T硬盘/ST6000VX001","value":_vm.d9}}),_c('van-cell',{attrs:{"title":"5口路由器/TL-R473GP-AC","value":_vm.d10}})],1)],1)]),_c('van-overlay',{attrs:{"show":_vm.show},on:{"click":function($event){_vm.show = false}}},[_c('div',{staticClass:"overlay"},[_c('div',{staticClass:"weixin"},[_c('div',{staticClass:"block"},[_c('img',{attrs:{"src":_vm.htmlUrl}}),_c('div',[_vm._v("长按图片保存和分享")])])])])]),_c('van-overlay',{attrs:{"show":_vm.show1},on:{"click":function($event){_vm.show1 = false}}},[_c('div',{staticClass:"overlay"},[_c('img',{attrs:{"src":require("../assets/tp.png")}})])]),_c('van-overlay',{attrs:{"show":_vm.show2},on:{"click":function($event){_vm.show2 = false}}},[_c('div',{staticClass:"overlay"},[_c('img',{staticStyle:{"width":"200px","height":"200px"},attrs:{"src":require("../assets/iconewm.png")}})])]),(_vm.flag == true)?_c('div',{staticClass:"position list1",on:{"click":function($event){_vm.show2 = !_vm.show2}}},[_c('van-icon',{attrs:{"name":"qr","size":"25px"}})],1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"plating"},[_c('span',[_vm._v("摄像头计算")])])}]

export { render, staticRenderFns }