








































































































/* eslint-disable */
import html2canvas from "html2canvas";
import { Component, Vue, Watch } from "vue-property-decorator";
import * as math from "mathjs";
@Component({
  components: {},
})
export default class HOME extends Vue {
  show: any = false;
  show1: any = false;
  show2: any = false;
  flag1: any = false;
  imageList: any = require("../assets/tp.png");
  // 输入
  c5 = "";
  c6 = "";
  // 常量
  c7 = 1.7;
  c8 = 90;
  c9 = 48;
  c10 = 2;
  c11 = 4;
  c12 = 70;
  c13 = 0.5;

  // 过度计算值
  c14 = 0;
  c15 = 0;
  c16 = 0;
  c17 = 0;
  c18 = 0;
  c19 = 0;
  c20 = 0;
  c21 = 0;
  // 输出
  c22 = 0;
  // 过度计算值
  c23 = 0;
  c24 = 0;
  // 输出
  c25 = 0;

  // 输入
  d2: any = '';
  d3: any = '';
  d4: any = '-';
  d5: any = '-';
  d6: any = '-';
  d7: any = '-';
  d8: any = '-';
  d9: any = '-';
  d10: any = '-';

  r1: any = '-';
  r2: any = '-';
  r2Label: any = '';
  htmlUrl: any = "";
  flag: any = false;
  @Watch('c5')
  onC5Change() {
    this.blur();
  }

  @Watch('c6')
  onC6Change() {
    this.blur();
  }

  @Watch('d2')
  onD2Change() {
    this.blur1();
  }

  @Watch('d3')
  onD3Change() {
    this.blur1();
  }

  isRealNum(val: any){
　　if (val === '' || val == null) {
      return false;
　　}
    if (!isNaN(val)) {
　　　 return true;
　　} else {
　　　　return false;
　　}
  }
  blur1() {
    if (!(this.d3 === '')) {
      const result = +(this.d2 || 0) + +this.d3;

      if (result < 1) {
        this.d4 = '';
      } else if (result < 7) {
        this.d4 = 1;
      } else if (result < 9) {
        this.d4 = 1;
      } else if (result < 13) {
        this.d4 = '';
      } else {
        this.d4 = '轻量版最多支持12路';
      }

      if (result < 9) {
        this.d5 = '';
      } else if (result < 13) {
        this.d5 = 1;
      } else {
        this.d5 = '轻量版最多支持12路';
      }

      if (result < 1) {
        this.d6 = '';
      } else if (result < 8) {
        this.d6 = 1;
      } else if (result < 9) {
        this.d6 = '';
      } else if (result < 13) {
        this.d6 = '';
      } else {
        this.d6 = '轻量版最多支持12路';
      }

      if (result < 8) {
        this.d7 = '';
      } else if (result < 7) {
        this.d7 = 1;
      } else if (result < 13) {
        this.d7 = 1;
      } else {
        this.d7 = '轻量版最多支持12路';
      }

      if (result < 1) {
        this.d8 = '';
      } else if (result < 8) {
        this.d8 = 1;
      } else if (result < 13) {
        this.d8 = '';
      } else {
        this.d8 = '轻量版最多支持12路';
      }

      if (result < 8) {
        this.d9 = '';
      } else if (result < 13) {
        this.d9 = 1;
      } else {
        this.d9 = '轻量版最多支持12路';
      }
      if (this.d2 == '' || this.d2 == '0') {
        if (this.d3 <= 4) {
          this.d10 = 1;
          this.d4 = '-';
          this.d5 = '-';
          this.d6 = '-';
          this.d7 = '-';
          this.d8 = '-';
          this.d9 = '-';
        } else {
          this.d10 = '-';
        }
      } else {
        this.d10 = '-';
      }
    } else {
      this.d4 = '-';
      this.d5 = '-';
      this.d6 = '-';
      this.d7 = '-';
      this.d8 = '-';
      this.d9 = '-';
    }
  }
  blur() {
    this.r2Label = '';
    if (this.c5 && this.c6) {
      this.change();
      if (isNaN(this.c22)) {
        this.r1 = '-';
      } else if (this.c22 <= 4){
        this.r1 = 4.0;
      } else {
        this.r1 = (this.c22).toFixed(1);
      }
      if (this.r1 >= 6) {
        this.r2Label = '距离超出限制，请联系Aibee设计师!';
        this.r1 = 6.0;
      }
      if (this.r2 <= 4) {
        this.r2 = 4.0;
      } else {
        this.r2 = 6.0;
      }
    } else {
      this.r1 = '-';
      this.r2 = '-';
    }
  }

  open() {
    this.flag1 = !this.flag1;
  }
  isWeiXin(){
    const ua: any = window.navigator.userAgent.toLowerCase();
    return ua.match(/MicroMessenger/i) == 'micromessenger';
  }
  toImage() {
    let refs: any = this.$refs.imageTofile;
    html2canvas(refs, {
      backgroundColor: null,
    }).then((canvas) => {
      let url = canvas.toDataURL("image/png");
      if (this.isWeiXin()) {
        this.htmlUrl = url;
        this.show = true;
      } else {
        const a = document.createElement("a");
        a.setAttribute("download", "指标截图");
        a.setAttribute("target", "_blank");
        a.setAttribute("href", url);
        a.click();
      }
    });
  }

  change() {
    this.setC14();
    this.setC15();
    this.setC16();
    this.setC17();
    this.setC18();
    this.setC19();
    this.setC20();
    this.setC21();
    this.setC22();
    this.setC23();
    // this.setC24();
  }
  setC14() {
    this.c14 = this.format(
      math.evaluate(
        `(-((${this.c10}+${this.c11})*tan(${this.c9}/180*pi)-${this.c7})+sqrt(((${this.c10}+${this.c11})*tan(${this.c9}/180*pi)-${this.c7})^2-4*tan(${this.c9}/180*pi)*${this.c5}*(tan(${this.c9}/180*pi)*(${this.c5}-${this.c7})-(${this.c10}+${this.c11}))))/(2*tan(${this.c9}/180*pi))`
      )
    );
  }
  setC15() {
    this.c15 = this.format(
      math.evaluate(`atan(${this.c5}/${this.c14})/pi*180`)
    );
  }
  setC16() {
    this.c16 = this.format(
      math.evaluate(
        `${this.c5}*tan(pi/2-atan(${this.c5}/${this.c14})+${this.c9}/2/180*pi)`
      )
    );
  }
  setC17() {
    this.c17 = this.format(
      math.evaluate(
        `sqrt(${this.c5}^2+${this.c16}^2)*tan(${this.c8}/2/180*pi)*2`
      )
    );
  }
  setC18() {
    this.c18 = this.format(
      math.evaluate(`${this.c17}*(${this.c10}+${this.c14})/${this.c16}`)
    );
  }
  setC19() {
    this.c19 = this.format(
      math.evaluate(`${this.c17}*${this.c14}/${this.c16}`)
    );
  }
  setC20() {
    this.c20 = this.format(
      math.evaluate(
        `${this.c11}*(${this.c5}-${this.c6})/(${this.c6}-${this.c7})-${this.c10}`
      )
    );
  }
  setC21() {
    this.c21 = this.format(math.evaluate(`max(${this.c14},${this.c20})`));
  }
  setC22() {
    this.c22 = this.format(
      math.evaluate(`max(${this.c14},${this.c20})+${this.c10}`)
    );
  }
  setC23() {
    this.c23 = this.format(math.evaluate(`2560/${this.c12}*${this.c13}`));
  }
  setC24() {
    this.c24 = this.format(
      math.evaluate(
        `sqrt(((${this.c23}/2/tan(${this.c8}/2/180*pi))^2-${this.c5}^2))`
      )
    );
  }
  format(value: any) {
    const precision = 14;
    return +math.format(value, precision);
  }
  created() {
    var str = window.navigator.userAgent;
    if (str.toLowerCase().indexOf("mobile") == -1) {
      this.flag = true;
    } else {
      this.flag = false;
    }
  }
}
